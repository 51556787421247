.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  min-height: 100vh;
  margin: 0;
  padding: 0;

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
}
