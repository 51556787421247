/* ----------------------------------------------------------------------------
custom media queries
---------------------------------------------------------------------------- */
@custom-media --viewport-small (max-width: 44.9em);
@custom-media --viewport-medium (min-width: 45em);
@custom-media --viewport-large (min-width: 90em);
@custom-media --viewport-retina (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi);

/* ----------------------------------------------------------------------------
custom properties
---------------------------------------------------------------------------- */
:root {
  /* colors */
  --color-text: #fff;
  --color-sheet: #191919;
  --color-accent: #ff46df;

  /* spacing */
  --space-base: 2rem;
  --space-scale: 2;
  --space-xxs: calc(var(--space-xs) / var(--space-scale));
  --space-xs: calc(var(--space-s) / var(--space-scale));
  --space-s: calc(var(--space-m) / var(--space-scale));
  --space-m: var(--space-base);
  --space-l: calc(var(--space-m) * var(--space-scale));
  --space-xl: calc(var(--space-l) * var(--space-scale));
  --space-xxl: calc(var(--space-xl) * var(--space-scale));

  /* font stacks */
  --font-main: 'Inter', 'Helvetica Neue', helvetica, 'Apple Color Emoji', arial, sans-serif;

  /* type sizes */
  --type-base: max(1.6em, min(calc(0.5em + 1vw), 2.1em));
  --type-scale-s: 1.1;
  --type-scale-l: 1.333;
  --type-xxxs: calc(var(--type-xxs) / var(--type-scale-s));
  --type-xxs: calc(var(--type-xs) / var(--type-scale-s));
  --type-xs: calc(var(--type-s) / var(--type-scale-s));
  --type-s: calc(var(--type-m) / var(--type-scale-s));
  --type-m: 1em;
  --type-l: calc(var(--type-m) * var(--type-scale-l));
  --type-xl: calc(var(--type-l) * var(--type-scale-l));
  --type-xxl: calc(var(--type-xl) * var(--type-scale-l));
  --type-xxxl: calc(var(--type-xxl) * var(--type-scale-l));
  --type-xxxxl: calc(var(--type-xxxl) * var(--type-scale-l));

  /* line heights */
  --leading-fluid: calc(0.75rem + 1.25em);
}
