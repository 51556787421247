/* ----------------------------------------------------------------------------
custom fonts
---------------------------------------------------------------------------- */

@font-face {
  font-family: 'Inter';
  font-weight: 100 900;
  font-stretch: normal;
  font-named-instance: 'Regular';
  src: url('/assets/Inter-roman-subset.var.woff2') format('woff2');
  font-display: swap;
}
