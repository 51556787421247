.hero__title {
  margin: var(--space-l) 0 0 0;
  font-size: min(20vw, 1800%);
  line-height: 0.75;
  font-weight: 800;
  letter-spacing: -0.02em;

  @media (--viewport-large) {
    font-size: 1400%;
  }
}
