/* ----------------------------------------------------------------------------
element styles
---------------------------------------------------------------------------- */

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  line-height: var(--leading-fluid);
}

::selection,
::-moz-selection {
  color: var(--color-sheet);
  background-color: var(--color-text);
}

html {
  font-size: 10px;
  background-color: var(--color-sheet);
  min-height: -webkit-fill-available;
}

body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;
  overflow-x: hidden;
  font-family: var(--font-main);
  font-size: var(--type-base);
  text-align: center;
  color: var(--color-text);
  background-color: var(--color-sheet);

  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }
}

p,
ul {
  margin: var(--space-m) 0;
  font-weight: 350;
}

b,
strong {
  font-weight: 800;
}

a,
a:visited {
  color: var(--color-text);
  text-decoration-thickness: 0.1em;
  transition: text-decoration-thickness 0.1s ease;
}

a:hover {
  text-decoration-thickness: 0.2em;
}

img,
embed,
object,
svg,
video {
  max-width: 100%;
  height: auto;
}
