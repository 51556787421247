.grid {
  display: grid;
  grid-template-columns: 1fr minmax(80vw, 100%) 1fr;
  align-items: start;
  column-gap: min(5vw, 5rem);

  & > .grid {
    grid-column: 1 / -1;
  }

  @media (--viewport-medium) {
    grid-template-columns: 1fr repeat(12, minmax(3.75rem, 100%)) 1fr;
    column-gap: min(2.5vw, 3.75rem);
  }

  @media (--viewport-large) {
    grid-template-columns: 1fr repeat(12, 8rem) 1fr;
    column-gap: 3.5rem;
  }
}

.grid__item {
  grid-column-start: 2;

  &.--bleed-left { grid-column: 1 / span 2; }
  &.--bleed-right { grid-column-end: -1; }
  &.--bleed-full { grid-column: 1 / -1; }

  @media (--viewport-medium) {
    grid-column: 2 / span 10;

    &.--span-3 { grid-column-end: span 3; }
    &.--span-4 { grid-column-end: span 4; }
    &.--span-5 { grid-column-end: span 5; }
    &.--span-6 { grid-column-end: span 6; }
    &.--span-7 { grid-column-end: span 7; }
    &.--span-8 { grid-column-end: span 8; }
    &.--span-9 { grid-column-end: span 9; }
    &.--span-10 { grid-column-end: span 10; }
    &.--span-11 { grid-column-end: span 11; }
    &.--span-12 { grid-column: 2 / span 12; }

    &.--start-1 { grid-column-start: 2; }
    &.--start-2 { grid-column-start: 3; }
    &.--start-3 { grid-column-start: 4; }
    &.--start-4 { grid-column-start: 5; }
    &.--start-5 { grid-column-start: 6; }
    &.--start-6 { grid-column-start: 7; }
    &.--start-7 { grid-column-start: 8; }
    &.--start-8 { grid-column-start: 9; }
    &.--start-9 { grid-column-start: 10; }
    &.--start-10 { grid-column-start: 11; }
    &.--start-auto { grid-column-start: auto; }
  }
}
