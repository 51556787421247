/* ----------------------------------------------------------------------------
text: masquerade text... as other text
---------------------------------------------------------------------------- */

.text {

  &.--size-xxxs { font-size: var(--type-xxxs); }
  &.--size-xxs { font-size: var(--type-xxs); }
  &.--size-xs { font-size: var(--type-xs); }
  &.--size-s { font-size: var(--type-s); }
  &.--size-m { font-size: var(--type-m); }
  &.--size-l { font-size: var(--type-l); }
  &.--size-xl { font-size: var(--type-xl); }
  &.--size-xxl { font-size: var(--type-xxl); }
  &.--size-xxxl { font-size: var(--type-xxxl); }
  &.--size-xxxxl { font-size: var(--type-xxxxl); }
}
