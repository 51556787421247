.noises {
  display: block;
  margin: var(--space-l) 0 0 0;
}

.noises__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
  gap: 1em;
}

.noises__item {
  display: block;
  list-style-type: none;
}

.noises__button {
  display: block;
  width: 100%;
  padding: var(--space-m) var(--space-l);
  font-family: var(--font-main);
  font-size: var(--type-xl);
  font-weight: 600;
  border: 0.2rem solid var(--color-text);
  color: var(--color-text);
  background-color: var(--color-sheet);
  border-radius: 5em;
  transition: all 0.15s ease;

  &[aria-pressed="true"] {
    color: var(--color-sheet);
    background-color: var(--color-text);
  }

  &:hover {
    @media (hover: hover) {
      font-weight: 900;
      cursor: pointer;
    }
  }
}

.noise__visualizer {
  --color: var(--color-accent);

  pointer-events: none;
  overflow: hidden;
  position: absolute;
  top: -0.2rem;
  left: 0;
  width: 100%;
  height: 50vh;
  opacity: 0;
  visibility: hidden;

  @nest .noises__item:nth-child(2) & {
    --color: #38ff5a;
  }

  @nest .noises__item:nth-child(3) & {
    --color: #f1ff20;
  }

  @nest .noises__item:nth-child(4) & {
    --color: #00d0ff;
  }

  @nest .noises__item:nth-child(5) & {
    --color: #6a2cff;
  }

  @nest .noises__item:nth-child(6) & {
    --color: #ff6c16;
  }

  @nest .--active & {
    opacity: 1;
    visibility: visible;
  }

  & canvas {
    margin-top: -0.2rem;
    width: 100% !important;
    height: 100% !important;
  }
}
